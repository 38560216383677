import logo from './logo.svg';
import './App.css';

import MessengerCustomerChat from 'react-messenger-customer-chat';
import FloatingWhatsApp from 'react-floating-whatsapp'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <MessengerCustomerChat
        pageId="1705892916387178"
        appId="583835686166015"        
      />
      <FloatingWhatsApp
        styles={{ bottom: "7rem"}}
        phoneNumber="584145807408"
        accountName="Turismo 123"
        allowClickAway
        notification
        notificationDelay={60000} // 1 minute
        notificationSound
      />
    </div>
  );
}

export default App;
